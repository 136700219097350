<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-screen">
				<el-form inline size="small">
					<el-form-item :label="$t('teacher.formItem.belongClass')">
						<el-select v-model="screenForm.class_id" class="w_all" clearable>
							<el-option v-for="(item,index) in classOption" :key="index" :label="item.name"
								:value="item.class_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.account')">
						<el-input v-model="screenForm.user_name" clearable :placeholder="$t('teacher.placeholder.account')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.studentName')">
						<el-input v-model="screenForm.student_name" clearable :placeholder="$t('teacher.placeholder.studentName')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.shopName')">
						<el-input v-model="screenForm.store_name" clearable :placeholder="$t('teacher.placeholder.shopName')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearch"><i class="el-icon-search"></i></el-button>
						<span class="search-clear" @click="handleClearSearch"><i class="el-icon-brush"></i>{{$t('teacher.actions.clear')}}</span>
					</el-form-item>
				</el-form>
			</div>
			<div class="t-page-notice">
				<h2>{{$t('teacher.score.tips1')}}</h2>
				<p>{{$t('teacher.score.tips2')}}</p>
				<p>{{$t('teacher.score.tips3')}}</p>
			</div>
			<!-- <div class="t-page-screen">
				<el-form inline size="small">
					<el-form-item>
						<el-button type="danger" @click="handleCalculateSystem">智能评分计算</el-button>
					</el-form-item>
				</el-form>
			</div> -->
			<div class="t-page-table">
				<el-table :data="pageList" size="small" @sort-change="handleSortPageChange">
					<el-table-column :label="$t('teacher.tableHead.rank')" prop="rank" width="100px"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.account')" prop="user_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.studentName')" prop="real_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.shopName')" prop="store_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.class')" prop="class_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.systemScore')" prop="system_score" align="center" sortable="custom"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.teacherScore')" prop="teacher_rating" align="center" sortable="custom"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.totalScore')" prop="total_score" align="center" sortable="custom"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.details')" align="center">
						<template slot-scope="scope">
							<ul class="item-handle">
								<li><router-link :to="{name:'teacherScoreSystem',params: {id: scope.row.member_id}}">{{$t('teacher.actions.systemScore')}}</router-link></li>
								<li><router-link :to="{name:'teacherScoreManage',params: {id: scope.row.member_id}}">{{$t('teacher.actions.teacherScore')}}</router-link></li>
							</ul>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="t-page-bottom">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pageCurr" :page-size="pageSize" layout="total, prev, pager, next"
					:prev-text="$t('teacher.pagination.prevText')" :next-text="$t('teacher.pagination.nextText')" :total="pageTotal">
				</el-pagination>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchClass, getScoreRanking, calculateSystem } from '@/api/teacher';
	const defaultForm = {
		user_name: '',
		student_name: '',
		store_name: '',
		class_id: ''
	}
	export default {
		data() {
			return {
				screenForm: Object.assign({}, defaultForm),
				classOption: [],
				pageList: [],
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
				sortSign: '',
				sortType: '',
			}
		},
		created() {
			this.getClassOption();
			this.getPageList();
		},
		methods: {
			getClassOption() {
				let params = {
					page_index: 0,
					page_size: 1000
				}
				fetchClass(params).then(response => {
					this.classOption = response.data.list;
				})
			},
			getPageList() {
				let params = {
					...this.screenForm,
					sort_sign: this.sortSign,
					sort_type: this.sortType,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize
				}
				if (this.datetime) {
					params.begin_time = this.datetime[0];
					params.end_time = this.datetime[1];
				}
				getScoreRanking(params).then(response => {
					this.pageList = response.data.list;
					this.pageTotal = parseInt(response.data.count);
				})
			},
			handleSearch() {
				this.pageCurr = 1;
				this.getPageList();
			},
			handleClearSearch() {
				this.screenForm = Object.assign({}, defaultForm);
				this.pageCurr = 1;
				this.getPageList();
			},
			handleSizeChange(val) {
				this.pageSize = val;
			},
			handleCurrentChange(val) {
				this.pageCurr = val;
				this.getPageList();
			},
			handleSortPageChange(column) {
				switch (column.prop) {
					case 'system_score':
						this.sortSign = 1;
						break;
					case 'teacher_rating':
						this.sortSign = 2;
						break;
					case 'total_score':
						this.sortSign = 3;
						break;
				}
				if (column.order == 'ascending') {
					this.sortType = 'asc'
				} else {
					this.sortType = 'desc'
				}
				this.pageCurr = 1;
				this.getPageList()
			},
			handleCalculateSystem(){
				this.$confirm(this.$t('teacher.confirm.calculateSystem'),'',{
					confirmButtonText: this.$t('teacher.actions.confirm1'),
					cancelButtonText: this.$t('teacher.actions.cancel1'),
					type: 'warning'
				}).then(()=>{
					calculateSystem({ uuid: this.$store.getters.uuid }).then(()=>{
						this.$message.success(this.$t('teacher.successMsg.handle'))
					})
				}).catch(()=>{
					this.$message(this.$t('teacher.cancelMsg.handle'))
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;

		.t-page-title {
			padding-bottom: 20px;

			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.t-page-screen {
			// padding-top: 18px;
			// border-top: 1px solid #EBEEF5;
			::v-deep .el-form {
				display: flex;
				flex-wrap: wrap;
				.el-form-item {
					width: 320px;
					flex-shrink: 0;
					display: flex;
					padding: 0 9px;
					margin-right: 0;
					margin-bottom: 16px;
					box-sizing: border-box;
					.el-form-item__label {
						min-width: 68px;
					}
					.el-form-item__content {
						flex: 1;
						width: 0;
					}
				}
			}
			.w100 {
				width: 100px;
			}
			.w_all {
				width: 100%;
			}

			.search-clear {
				cursor: pointer;
				margin-left: 12px;

				i {
					margin-right: 4px;
				}
			}
		}

		.t-page-notice {
			padding: 20px;
			background-color: $--seller-notice-background;
			margin-bottom: 20px;

			h2 {
				font-size: 14px;
				font-weight: bold;
				margin-bottom: 10px;
			}

			p {
				color: #666666;
				line-height: 20px;
				font-size: 12px;
			}
		}

		.t-page-table {
			::v-deep .el-table {
				th.el-table__cell {
					background-color: $--seller-thead-background-1;
				}
				.caret-wrapper {
					height: 24px;
				}
				.sort-caret.ascending {
					top: 0;
				}
				.sort-caret.descending {
					bottom: 0;
				}
			}
			.item-handle {
				li {
					display: inline-block;
					margin: 0 5px;
				}
				a {
					color: $--seller-link-color;
					&:hover {
						color: $--seller-link-hover;
					}
				}
			}
		}

		.t-page-bottom {
			padding: 15px 0;
			text-align: center;
		}
	}
</style>
